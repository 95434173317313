.cancelCloseWorkRequestPopup {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  &__head {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 48px;
    justify-content: end;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__buttons {
    display: flex;
    justify-content: end;
    align-items: end;
  }
}
