.alert-block {
  display: flex;
  gap: 8px;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  background: #EBF3FE;
  color: #2F80ED;

  &__icon {
    width: 24px;
    height: 24px;
  }
  &__text {
    flex: 1;
    width: 100%;
    padding-right: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}
