.contentStatus {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333333;
  height: 24px;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background-color: #EFEFEF;
  border-radius: 4px;
  width: fit-content;


  &.contentPriority0 {
    background: #9FDFFF;
    color: #333333;
  }

  &.contentPriority1 {
    background: #EFEFEF;
    color: #333333;
  }

  &.contentPriority2 {
    background: #FFCF52;
    color: #333333;
  }

  &.contentPriority3 {
    background: #6FCF97;
    color: #FFFFFF;
  }
  &.contentPriority4 {
    background: #EFEFEF;
    color: #333333;
  }
}
