.viewTaskPopup {

  .task-title {
    margin-bottom: 48px;
  }

  &__times {
    display: flex;
    align-items: flex-start;
    gap: 15px;

    .viewTaskPopup__item {
      width: calc(100% / 2);
    }

    .viewTaskPopup__itemTitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .viewTaskPopup__itemText {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #A5A7A8;
    }
  }

  &__description {
    margin-bottom: 8px;
  }

  &__descriptionTitle {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__descriptionText {
    color: #A5A7A8;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-height: 172px;
    overflow-y: auto;
  }

  &__block {
    width: 100%;
    margin-bottom: 48px;
  }

  &__departmentBlock {
    width: fit-content;
    position: relative;
  }

  &__departmentBlockButton {
    width: fit-content;
    margin-top: 16px;
  }

  &__blockTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
  }
}
