.risk {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.risk--0 {
  color: #2EAE63;
}
.risk--1 {
  color: #E1A50A;
}
.risk--2 {
  color: #FA9131;
}
.risk--3 {
  color: #E35B5B;
}

.riskImage {
  width: 24px;
  height: 24px;
  background-size: contain;
}
.riskImage--0 {
  background: url('../../assets/risk/lowRisk.svg');
}
.riskImage--1 {
  background: url('../../assets/risk/middleRisk.svg');
}
.riskImage--2 {
  background: url('../../assets/risk/hightRisk.svg');
}
.riskImage--3 {
  background: url('../../assets/risk/criticalRisk.svg');
}

.risk-block {
  padding: 12px 8px;
  justify-content: start;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;


  &.risk--0 {
    background-color: #F1FAF5;
  }
  &.risk--1 {
    background-color: #FFFAEE;
  }
  &.risk--2 {
    background-color: #FFF4EB;
  }
  &.risk--3 {
    background-color: #FFF3F3;
  }
}
