.combobox {
  &__menu {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.20);

    .new-select__menu {
      position: static;
      box-shadow: none;
      max-width: fit-content;
    }
  }
  &__indicatorContainer {
    align-items: center;
    align-self: stretch;
    display: block;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 12px 12px 12px 0;
  }
}
