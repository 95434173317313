.application {
  min-width: 308px;
  max-width: 308px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  cursor: pointer;
  user-select: none;
  transition: opacity 100ms;
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  &.unPublished {

    .warningSign {
      display: block;
    }

    .contentStatus, .application__title, .application__tasks__quantity {
      opacity: 0.5;
    }
  }

  .warningSign {
    display: none;
    position: absolute;
    right: 16px;
  }

  &:active {
    opacity: 0.5;
  }

  &:not(:first-child) {
    margin-left: 8px;
  }

  &__header {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    padding-right: 21px;

    > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }
  }

  .contentPriority {
    margin-right: 16px;
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }

  &__tasks__quantity {
    display: inline-flex;
    margin-top: 4px;
    color: #A5A7A8;
    font-size: 14px;
    line-height: 20px;
    text-wrap: nowrap;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__tasks__department {
    max-width: 102px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-icon {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-skeleton {
    min-height: 122px;
  }
}
