.work-request-popup {

  .infoBlock {
    background: #EBF3FE;
    color: #2F80ED;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__headerContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__bodyContainer {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__tasksContainer {
    display: flex;
    flex-direction: column;

    &__title {
      color: #333333;
      margin-bottom: 8px;
    }

    &__tasks {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      border: thin solid #EFEFEF;
    }

    &__task {
      padding: 12px 24px;
      border-bottom: thin solid #EFEFEF;
      display: flex;
      gap: 16px;
      justify-content: space-between;
      align-items: center;

      &__avatar {
        padding: 2px 0;
        width: 48px;
        height: 48px;
        border-radius: 100%;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        background: #EFEFEF;
        justify-content: center;
        align-items: center;
      }
      &__taskBody {
        flex: 1;
      }
      &__date {
        padding: 2px 0;
        font-size: 14px;
        line-height: 20px;
      }
      &__extraData {
        display: flex;
        gap: 8px;
      }
      &__title {
        color: #2F80ED;
      }
      &__icon {

      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__button {
      margin-top: 24px;
      color: #2F80ED;
      background: transparent;
      border: thin dashed #2F80ED;
      width: 100% !important;
      padding: 8px !important;
    }
  }

  &__propertyItemsGroup{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__propertyItemsGroupRow{
    display: flex;
    gap: 24px;

    .new-select {
      width: 50%;
    }
  }
  &__propertyItem {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
      color: #333333;
      font-size: 16px;
      line-height: 24px;
    }
    &__value {
      color: #A5A7A8;
      font-size: 16px;
      line-height: 24px;
    }

    .files {
      margin-top: 4px;
    }
    &.table {
      gap: 8px
    }
  }
  .alert-block {
    margin-top: 8px;
    margin-bottom: 24px;
  }
  &__table{
    margin-top: 4px;
    max-height: 312px;

    &-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
      color: #000;
    }

    .ant-table-content {
      border-radius: 4px;
    }
    .ant-table-thead {
      .ant-table-cell {
        background: transparent;
        color: #A5A7A8;
        font-weight: 400;
      }
    }
    .ant-table-cell {
      padding: 10px 8px;
      width: fit-content;
      max-width: 400px;
      position: relative;

      &:nth-last-child(-n+2) {
        border-right: none;
      }
    }
    &-draggableBorder {
      position: absolute;
      width: 3px;
      height: 100%;
      right: -1.5px;
      top: 0;
      z-index: 100;

      &:hover {
        background: #2F80ED;
        cursor: col-resize;
        transition: .3s ease-in-out
      }
    }
  }
}
