.task-carousel {
  position: relative;
  width: 100%;

  &__arrows {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: -60px;
  }

  &__wrapper {
    //overflow: hidden;
    overflow-x: auto;
    width: calc(100% + 96px);
    position: relative;
    padding-top: 24px;
    margin-left: -48px;
    padding-left: 48px;
    padding-right: 48px;
  }

  &__inner {
    position: absolute;
    left: 65px;
    right: 0;
    top: 0;
    bottom: 0;

    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1963_36295)'%3E%3Cline x1='13.3536' y1='-0.646447' x2='-9.64645' y2='22.3536' stroke='%23C6C6C6'/%3E%3Cline x1='21.3536' y1='-0.646447' x2='-2.64645' y2='23.3536' stroke='%23C6C6C6'/%3E%3Cline x1='29.3536' y1='-0.646447' x2='4.35355' y2='24.3536' stroke='%23C6C6C6'/%3E%3Cline x1='37.3536' y1='-0.646447' x2='12.3536' y2='24.3536' stroke='%23C6C6C6'/%3E%3Cline x1='45.3536' y1='-0.646447' x2='20.3536' y2='24.3536' stroke='%23C6C6C6'/%3E%3Cline x1='5.35355' y1='-0.646447' x2='-17.6464' y2='22.3536' stroke='%23C6C6C6'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1963_36295'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    .taskContainer {
      width: 300px;

      &.lvl1 {
        margin-left: 20px;
        z-index: 2;
        width: 280px;
      }
      &.lvl2 {
        margin-left: 40px;
        z-index: 3;
        width: 260px;
      }

      &.active {
        z-index: 999;
      }
    }
  }

  &__row {
    display: flex;
    position: relative;
    transition: .35s ease;

    &_head {
      > div {
        padding-bottom: 22px;
      }
    }
  }

  &__col {
    border-right: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 5px;

    &_offset {
      min-width: 64px;
      max-width: 64px;
      background: #fff;
    }

    &_person {
      text-align: center;
      max-width: 300px;
      min-width: 300px;
      transition: .35s ease;
      position: relative;

      &-first {
        margin-right: 48px;

        &:after {
          position: absolute;
          content: '';
          height: 100%;
          top: 0;
          bottom: 0;
          right: -48px;
          border-right: 1px solid #EFEFEF;
        }
      }

      .user-avatar {
        margin: 0 auto;
        position: relative;

        &--after {
          &:after {
            content: '';
            position: absolute;
            background: #DDDDDD;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            left: 2px;
            top: 2px;
          }

          &-offline {
            background: #FFF3F3;

            &:after {
              background: #FE8282;
            }
          }
        }
      }
    }

    &:hover .task-carousel__remove-person {
      opacity: 1;
    }

    &_task {
      min-height: 96px;
      max-height: 96px;
      overflow: visible;
    }

    &_time {
      min-width: 64px;
      max-width: 64px;
      min-height: 96px;
      max-height: 96px;
      padding: 12px;
      background: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
      position: sticky;
      left: -48px;
      z-index: 5;
    }
  }

  &__counter {
    margin-top: 9px;
    margin-bottom: 5px;
    color: #A5A7A8;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
  }

  &__name {
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    text-align: center;
    font-weight: 400;
    margin-bottom: 5px;
  }

  &__specialisation {
    max-width: 180px;
    font-size: 12px;
    color: #A5A7A8;
    line-height: 14px;
    text-align: center;
    margin: 0 auto 9px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__no-time-count {
    background: #FAFAFA;
    border-radius: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #333333;
    padding: 0 20px;
    width: calc(100% - 2px);
    margin: 0 auto 4px;
  }

  &__add-person {
    display: block;
    color: #2F80ED;
    width: 100%;
  }

  &__plus-iframe {
    display: block;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
  }

  &__plus-circle {
    margin: 0 auto;
    cursor: pointer;
    margin-bottom: 11px;
    border: 1px dashed #2F80ED;
    color: #2F80ED;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 20px;

    &_align-left {
      margin: 0;
      margin-right: 10px;
    }

    &.square {
      margin: 0;
      border-radius: 5px;
      position: relative;

      .ant-image {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__add-person & {
    &__counter,
    &__name,
    &__specialisation {
      color: #2F80ED;
    }
  }

  &__remove-person {
    position: absolute;
    display: block;
    top: 8px;
    left: 184px;
    transition: 0.3s ease-in-out;
    opacity: 0;

    > svg {
      margin: 0;
    }
  }
}

.preview {
  position: relative;
  width: auto !important;

  &__item {
    cursor: pointer;
    color: #2F80ED;
    width: 48px;
    height: 48px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 20px;

    margin: 0;
    border-radius: 5px;
    position: relative;

    .ant-image {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.border {
      border: 1px dashed #2F80ED;
    }
  }

  &__itemDelete {
    cursor: pointer;
    position: absolute;
    top: -6px;
    right: -6px;
    opacity: 0;
    transition: .3s ease-in-out;
  }

  input {
    display: none;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  .ant-image-mask {
    background: transparent;
  }

  &:hover {
    .preview__itemDelete {
      opacity: 1;
    }
  }
}
