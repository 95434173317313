.new-select {
  width: 100%;

  &__label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
  }

  &__select {
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
  }

  &__icon {
    display: flex;
    margin-right: 16px;
  }

  &__value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__multiValue {
    white-space: nowrap;
  }

  &__check {

  }

  &__error {
    color: #FE8282;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.16px;
    margin-top: 8px;
  }

  &__control {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: #FAFAFA;
    border-radius: 4px;
  }

  &__valueContainer {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    padding: 12px 16px 12px 16px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-column-gap: 6px;
    column-gap: 6px;
    text-overflow: ellipsis;
  }

  &__indicatorContainer {
    display: flex;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    color: hsl(0, 0%, 80%);
    padding: 0;
    box-sizing: border-box;
  }

  .CLEAR {
    display: none;
  }
}
